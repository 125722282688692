<template>
    <div class="row">
        <div class="col-12">
            <div class="detailTitle">
                <h1>{{ property.title }} <span class="icon-crown"></span></h1>
                <ul>
                    <li>{{ property.address }}</li>
                    <li>
                        <span class="brand-color">For {{ property.purpose }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-xl-8 mt-3">
            <div class="propertyDetail-content">
                <div class="display-card">
                    <ImageSlider :images="property_images" />
                    <div class="property-content p-3">
                        <div class="row">
                            <div class="col-xl-9">
                                <p>Property ID: {{ property.property_code }}</p>
                                <div v-if="property.purpose == 'rent'" class="pricing">
                                    <span>{{ property.currency }}: {{ property.price }}/{{ property.price_for }}</span>
                                </div>
                                <div v-else class="pricing">
                                    <span>{{ property.currency }}: {{ property.price }}</span>
                                </div>

                                <span class="categoryTag">
                                    <router-link :to="'/search?category=' + property_category.slug">{{
                                        property_category.title }}</router-link>
                                </span>
                            </div>
                            <!-- <div class="col-xl-3 property-mob">
                                <router-link to="" class="univ-btn w-100 text-center mb-2">Request a tour</router-link>
                                <router-link to="" class="w-100 text-center mb-2 univ-btn-line"><i class="fas fa-phone-alt"></i> Call Agent</router-link>
                            </div> -->
                        </div>
                        <section id="headerSection" class="section details-header--section">
                            <div class="details-header">
                                <ul class="details-header-nav">
                                    <li><a href="javascript:void(0);" @click="scrollToElement('sectionOverview')" v-bind:class="{'active-tab': activeTab ==='sectionOverview' }">Overview</a></li>
                                    <li><a href="javascript:void(0);" @click="scrollToElement('sectionAmenities')" v-bind:class="{'active-tab': activeTab ==='sectionAmenities' }" >Amenities</a></li>
                                    <li><a href="javascript:void(0);" @click="scrollToElement('sectionVideo')" v-bind:class="{'active-tab': activeTab ==='sectionVideo' }" > Video </a></li>
                                    <li><a href="javascript:void(0);" @click="scrollToElement('sectionViewMap')" v-bind:class="{'active-tab': activeTab ==='sectionViewMap' }" > Maps </a></li>
                                    <li><a href="javascript:void(0);" @click="scrollToElement('contactAgent')" v-bind:class="{'active-tab': activeTab ==='contactAgent' }" > Contact </a></li>
                                </ul>
                            </div>
                        </section>
                        <div v-intersect="handleIntersect" id="sectionOverview">
                            <h3>Specifications</h3>
                            <div class="specifications">
                                <ul class="d-flex properties-icons-fix">
                                    <li class="d-flex icons-fix-wrapper" v-if="property.livingroom"> Living Room: {{ property.livingroom }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.floor"> Floor: {{ property.floor }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.bathroom"> Bathroom: {{ property.bathroom }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.bedroom"> Bedroom: {{ property.bedroom }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.road_type"> Road Type: {{ property.road_type }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.kitchen"> Kitchen: {{ property.kitchen }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.land_mark"> Land Mark: {{ property.land_mark }}</li>
                                    <li v-if="property.land_size_type=='bkd'" class="d-flex icons-fix-wrapper"> Land Size [ Bigha/Katha/Dhur]: {{ property.bigha }} - {{ property.katha }} - {{ property.dhur }}</li>
                                    <li v-else class="d-flex icons-fix-wrapper"> Land Size [Ropani/Anna/Paisa/Dam]: {{ property.ropani }} - {{ property.anna }} - {{ property.paisa }} - {{ property.dam }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.buildup"> Buildup (Sqr. Ft.): {{ property.buildup }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.furnishing"> Furnishing: {{ property.furnishing }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.road_size"> Road Size: {{ property.road_size }}</li>
                                </ul>
                            </div>
                            <h3>Descriptions</h3>
                            <div class="description">
                                {{ property.description }}
                            </div>
                            <div class="sellerDetail">
                                <p><strong><i class="fa fa-user-circle-o" aria-hidden="true"></i> {{ sellerInfo.name }}</strong></p>
                                <p v-if="sellerInfo.address"><strong><i class="fa fa-map-marker" aria-hidden="true"></i></strong> {{ sellerInfo.address }}</p>
                                <div v-if="accessAgentInfo">
                                    <p><strong><i class="fa fa-mobile" aria-hidden="true"></i></strong> {{ sellerInfo.mobile_number }}</p>
                                    <p><strong><i class="fa fa-envelope" aria-hidden="true"></i></strong> {{ sellerInfo.email }}</p>
                                </div>
                                <div v-else>
                                    <span class="categoryTag" v-b-tooltip.hover title="You have to pay Rs.5 for contact info.">Get Contact Info</span>
                                </div>
                            </div>
                        </div>
                        <div v-intersect="handleIntersect" id="sectionAmenities">
                            <h3>Amenities</h3>
                            <div v-if="property_features.length > 0" class="extra-list mt-3 mb-3">
                                <ul class="d-flex properties-icons-fix">
                                    <li class="d-flex icons-fix-wrapper" v-for="(feature,f) in property_features" :key="f">
                                        <div class="icon-properties-wrapper"><img :src="feature.icon_path"></div> {{ feature.title }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-intersect="handleIntersect" id="sectionVideo">
                            <h3>Property Video </h3>
                            <div v-if="property.video_iframe" class="property-video" v-html="property.video_iframe" ></div>
                            <div v-else class="property-video">No video till now.</div>
                        </div>
                        <div v-intersect="handleIntersect" id="sectionViewMap">
                            <h3>Google Map</h3>
                            <div v-if="property.google_map" class="google-map" v-html="property.google_map" ></div>
                            <div v-else class="google-map">
                                <iframe width="100%" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" :src="'https://maps.google.com/maps?q='+mapAddrs+'&sll='+mapLat+','+mapLon+'&hl=en-US&z=14&amp;output=embed'"></iframe> 
                            </div>
                        </div>
                        <div v-intersect="handleIntersect" id="contactAgent">
                            <h3>Contact Agent</h3>
                            <ContactForm :requestData="contactData" :textMsg="textMsg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SimilarList :properties="similar_properties" />
    </div>
</template>
<script>
import axios from "axios";
import api from "@/services/api";
import SimilarList from "@/views/PropertyDetail/partials/SimilarList.vue";
import ImageSlider from "@/components/ImgSlider";
import ContactForm from "../../../components/ContactFrom.vue";
import store from "@/store";
import Helper from "../../../services/helper";
export default {
    name: "DetailContent",
    components: { SimilarList, ImageSlider, ContactForm },
    data() {
        return {
            page_slug: this.$route.params.slug,
            property: [],
            property_images: [],
            property_category: [],
            property_features: [],
            similar_properties: [],
            sellerInfo:"",
            contactData: {
                name: store.getters.user.name,
                email: store.getters.user.email,
                phone_number: store.getters.user.mobile_number,
                message: "",
                source: "AGENT_CONTACT",
            },
            textMsg: "",
            activeTab: "sectionOverview",
            mapAddrs:"KJ+Real+Estate+Nepal",
            mapLat:"27.6775994",
            mapLon:"85.2825197",
            accessAgentInfo: false,
            userId:store.getters.user.id,
        };
    },
    directives: {
        intersect: {
        // When the element intersects with the viewport
            inserted(el, binding) {
                const options = {
                    root: null, // Use the viewport as the root
                    rootMargin: '0px', // No margin
                    threshold: 0.5, // 50% of the element must be visible
                };

                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            // Call the provided callback function
                            binding.value(entry.target);
                        }
                    });
                }, options);

                // Start observing the element
                observer.observe(el);
            },
        },
    },
    mounted() {
        let app = this;
        app.renderDetail();
    },
    methods: {
        async renderDetail() {
            let app = this;
            Helper.showSpinner();
            await axios.get(api.getUrl("/properties/" + app.page_slug)).then(function (response) {
                if (response.data.success) {
                    app.quickModal = true;
                    document.title = response.data.data.title;
                    app.page_title = response.data.data.title;
                    app.page_description = response.data.data.description;
                    app.property = response.data.data;

                    app.property_images = app.property.property_images;
                    app.property_category = app.property.category;
                    app.property_features = app.property.property_features;
                    app.sellerInfo = app.property.client;
                    app.textMsg = "I am interested in this property [ID: " + app.property.property_code + "] ";
                    app.mapAddrs = app.property.mapAddress;
                    app.mapLon = app.property.lon;
                    app.mapLat = app.property.lat;

                    app.property.access_user.forEach(function (item) {
                        if(app.userId === item.client_id){
                            app.accessAgentInfo = true;
                        }
                    });
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
        scrollToElement(id) {
            const container = document.getElementById(id);
            this.activeTab = id;
            container.scrollIntoView({ behavior: 'smooth' });
        },
        handleIntersect(targetElement) {
            this.activeTab = targetElement.id;
        }
    },
    created() {
        this.$watch(() => this.$route.params, (newParam) => {
            this.page_slug = newParam.slug;
            this.renderDetail();
        })
    },
};
</script>
  